import React, { useContext } from "react";

import { Box } from "@mui/material";

import { MenuContext } from "../Context";

const BackgroundBlur = ({ onClick }) => {
  const { dimension, headPosition } = useContext(MenuContext);

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        overflow: "hidden",
        backdropFilter: "blur(1px)",
        zIndex: 1
      }}
    >
      <Box
        sx={{
          width: dimension,
          height: dimension,
          position: "relative",
          top: headPosition.y - dimension,
          left: headPosition.x
        }}
      >
        <Box
          className="scale-up-circle"
          sx={{
            position: "absolute",
            width: "300vw",
            height: "300vw",
            top: "-150vw",
            left: "-150vw",
            borderRadius: "50%",
            zIndex: 30,
            backdropFilter: "blur(1px)",
            backgroundColor: "rgba(255, 255, 255, 0.9)"
          }}
        />
      </Box>
    </Box>
  );
};

export default BackgroundBlur;
